import styled from 'styled-components'
import { Spin } from 'antd'

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  background: var(--hero-body-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function Loading() {
  return (
    <LoadingContainer>
      <Spin size="large"/>
    </LoadingContainer>
  )
}