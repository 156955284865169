import styled from 'styled-components'

const verifyStyle = {
  position: 'absolute',
  right: '10px',
  top: '50%',
  transform: 'translateY(-50%)',
  height: '45px',
  fontSize: 16
}

const LUser = styled.span.attrs({
  className: 'transition'
})`
  font-size: 14px;
  color: var(--momp-font-color);
  cursor: pointer;
  &:hover {
    color: var(--momp-pattern-color);
  }
`

export {
  verifyStyle,
  LUser
}