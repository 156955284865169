import { useState } from 'react'
import styled from 'styled-components'

/**
 * Layout
 */
import HeaderToast from './layout/toast'
import HeaderLine from './layout/line'
import HeaderTabs from './layout/tabs'

const HeaderContainer = styled.div`
  width: 100%;
  height: auto;
`

export default function HeaderLayout() {
  const [toast, setToast] = useState(true)
  
  return (
    <HeaderContainer>
      { toast ? <HeaderToast set={setToast} /> : ''}
      <HeaderLine />
      <HeaderTabs />
    </HeaderContainer>
  )
}