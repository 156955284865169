// 登录
import { useState } from 'react';
import styled from 'styled-components'
import InputText from '@/components/Input'
import { Space, Button } from 'antd'

const LoginContainer = styled.div`
  width: 100%;
`

const LUser = styled.span.attrs({
  className: 'transition'
})`
  font-size: 14px;
  color: var(--momp-font-color);
  cursor: pointer;
  &:hover {
    color: var(--momp-pattern-color);
  }
`

export default function Login(props) {
  const [userName, setUserName] = useState('')
  const [loading, setLoading] = useState(false)

  function login() {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }
  return (
    <LoginContainer>
      <Space
        size={25}
        style={{width: '100%'}}
        direction="vertical">
        <InputText label='邮箱/手机号' value={userName} set={setUserName} />
        <InputText label='密码' value={userName} set={setUserName} />
        <div className="flex-center-between">
          <LUser onClick={() => props.set(1)}>立即注册</LUser>
          <LUser onClick={() => props.set(2)}>找回密码</LUser>
        </div>
        <Button style={{
            height: '45px',
            fontSize: '16px'
          }} 
          type='primary'
          loading={loading}
          onClick={login}
          block>
          {
            !loading && (
              '立即登录'
            )
          }
        </Button>
      </Space>
    </LoginContainer>
  )
}