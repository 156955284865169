import { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Space, Input, Button } from 'antd'
import Icon from '@/utils/icon'

import Copy from '../Copy'

const {  TextArea  } = Input

const FooterContainer = styled.footer`
  width: 100%;
  background: rgba(0, 0, 0, .1);
  padding: 30px calc(5% + 20px);
`

const LogoName = styled.h2`
  font-size: 30px;
  line-height: 30px;
  color: #000;
`

const Li = styled.div`
  font-size: 14px;
  color: #878787;
  margin: 20px 0;
`

const FooterTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 0.5em;
  color: #000;
`

const Error = styled.div`
  width: 100%;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: #ff0000;
`

const form = {
  width: '100%',
  height: '40px'
}

export default function FooterLayout() {
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState('')
  const [line, setLine] = useState('')
  const [describe, setDescribe] = useState('')
  const [showTitle, setShowTitle] = useState(false)
  const [showLine, setShowLine] = useState(false)
  const [showDescribe, setShowDescribe] = useState(false)

  function submit() {
    if (!line) setShowLine(true)
    else if(!title) setShowTitle(true)
    else if(!describe) setShowDescribe(true)
    else {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 4000)
    }
  }

  function setTitleValue({target: {value}}) {
    if(value) setShowTitle(false)
    else  setShowTitle(true)
    setTitle(value)
  }

  function setLineValue({target: {value}}) {
    if(value) setShowLine(false)
    else  setShowLine(true)
    setLine(value)
  }

  function setDescribeValue({target: {value}}) {
    if(value) setShowDescribe(false)
    else  setShowDescribe(true)
    setDescribe(value)
  }

  return (
    <div>
       <FooterContainer>
        <Row gutter={40}>
          <Col xs={24} sm={24} md={12} lg={8} xl={5}>
            <LogoName>Momp</LogoName>
            <Li>
              <Space>
                <Icon type='icon-weizhixiangqing' />
                中国.浙江省.杭州市
              </Space>
            </Li>
            <Li>
              <Space>
                <Icon type='icon-gongsiyouxiang' />
                today151@163.com
              </Space>
            </Li>
            <Li>
              <Space>
                <Icon type='icon-dianhua' />
                183-0000-0000
              </Space>
            </Li>
            <Li>
              <Space>
                <Icon type='icon-weizhixiangqing' />
                中国.浙江省.杭州市
              </Space>
            </Li>
            <Li>
              <Space size={10}>
                <Icon type='icon-qq' />
                <Icon type='icon-weixin' />
                <Icon type='icon-weibo' />
                <Icon type='icon-tuitetwitter44' />
                <Icon type='icon-facebookfacebook51' />
                <Icon type='icon-google' />
              </Space>
            </Li>
          </Col>
          <Col xs={12} sm={12} md={12} lg={8} xl={5}>
            <FooterTitle>类别</FooterTitle>
            <Li>
              男士
            </Li>
            <Li>
              女士
            </Li>
            <Li>
              箱包
            </Li>
            <Li>
              鞋子
            </Li>
            <Li>
              牛仔服
            </Li>
            <Li>
              配饰
            </Li>
          </Col>
          <Col xs={12} sm={12} md={12} lg={8} xl={5}>
            <FooterTitle>基本信息</FooterTitle>
            <Li>关于我们</Li>
            <Li>联系我们</Li>
            <Li>条款和条件</Li>
            <Li>退换货</Li>
            <Li>送货方式</Li>
            <Li>隐私政策</Li>
          </Col>
          <Col xs={24} sm={24} md={12} lg={24} xl={9}>
            <Space
            style={{width: '100%'}}
            direction="vertical"
            size={0}>
              <FooterTitle>联系我们</FooterTitle>
              <Input
                style={form}
                onChange={setLineValue}
                placeholder="联系方式" />
              <Error>
                {
                  showLine && (<span>* 联系方式不能为空!</span>)
                }
              </Error>
              <Input
                style={form}
                onChange={setTitleValue}
                placeholder="标题"
                maxLength="12" />
              <Error>
                {
                  showTitle && (<span>* 标题不能为空!</span>)
                }
              </Error>
              <TextArea
                onChange={setDescribeValue}
                autoSize={{ minRows: 3, maxRows: 5 }}
                showCount
                maxLength={100}
                placeholder='问题描述' />
              <Error>
                {
                  showDescribe && (<span>* 问题描述不能为空!</span>)
                }
              </Error>
              <Button type="primary" style={form} loading={loading} onClick={submit} block>
                {
                  !loading ? '提交' : ' '
                }
              </Button>
            </Space>
          </Col>
        </Row>
      </FooterContainer>
      <Copy />
    </div>
  )
}