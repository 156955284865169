import { useEffect, useState } from 'react'
import styled from 'styled-components'
import '@/common/animate.min.css'
import Icon from '@/utils/icon'
import { imga2 } from '@/utils/image'
import { Image } from '@/components/Momp'
import { Space } from 'antd'
import { useWindowSize } from '@/hook/index'

const TagContainer = styled.div`
  width: 350px;
  height: 103px;
  max-width: 350px;
  position: fixed;
  background-color: rgba(255, 255, 255, .6);
  backdrop-filter: blur(10px);
  transition: all .25s ease;
  -moz-box-shadow: 0 0 12px rgba(0,0,0,.12);
  -webkit-box-shadow: 0 0 12px rgb(0 0 0 / 12%);
  box-shadow: 0 0 12px rgb(0 0 0 / 12%);
  border-radius: 5px;
  left: 35px;
  bottom: 55px;
  z-index: 350;
  padding: 10px;
  cursor: pointer;
  display: ${props => props.width < 600 ? 'none' : 'block'};
  &.none {
    left: -1000px;
  }
`

const closeStyle = {
  position: 'absolute',
  right: '10px',
  top: '10px',
  fontSize: '16px'
}

const Info = styled.div`
  font-size: 12px;
`

const Buy = styled.div`
  margin-bottom: 10px;
`

const GoodName = styled.div`
  color: #000;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
`

const eye = {
  position: 'absolute',
  right: 10,
  bottom: 10
}

export default function Tag() {
  const [ animate, setAnimate] = useState('')

  const offset = useWindowSize()

  function showAnimate() {
    setTimeout(() => {
        setAnimate('slideInLeft')
        hideAnimate()
    }, 5000);
  }

  function hideAnimate() {
    setTimeout(() => {
        setAnimate('slideOutLeft')
        showAnimate()
    }, 8000);
  }
  useEffect(() => {
    setAnimate('none')
    showAnimate()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  function handleClose() {
    setAnimate('slideOutLeft')
  }

  return (
    <TagContainer width={offset.width} className={['animated', animate]}>
      <Icon style={closeStyle} onClick={handleClose} type='icon-guanbi1' />
      <Space align='start'>
        <Image width='83px' height='83px' src={imga2} />
        <Info>
          <Buy>
            <span>Jack</span>已购买
          </Buy>
          <GoodName>
            精品男鞋
          </GoodName>
          <div className='flex-center'>
            <div style={{marginRight: 10}}>5分钟前</div>
            <Icon style={{color: 'green', fontSize: 16}} type='icon-anquan' />
            &nbsp;&nbsp;
            <div>已验证</div>
          </div>
        </Info>
      </Space>
      <Icon type='icon-eye' style={eye} />
    </TagContainer>
  )
}