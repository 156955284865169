import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Input
} from 'antd'

const InputContainer = styled.div`
  width: 100%;
  position: relative;
`

const inputStyle = {
  color: 'var(--momp-font-color)',
  width: 'calc(100% - 20px)',
  background: 'var(--hero-bg-light-color)',
  padding: '25px 0 9px 20px',
  boxSizing: 'content-box',
  fontSize: '16px'
}

const Label = styled.div`
  position: absolute;
  left: 20px;
  top: 18px;
  color: var(--momp-font-color);
  transition: .3s ease all;
  font-size: 16px;
  &.focus {
    top: 10px;
    font-size: 12px;
    color: var(--hero-pattern-color);
  }
`

export default function InputText(props) {
  const [focus, setFocus] = useState(false)

  function inputFocus() {
    setFocus(true)
  }

  function inputBlur() {
    !props.value && setFocus(false)
  }

  function inputChange(e) {
    props.set(e.target.value)
  }

  return (
    <InputContainer>
      <Label className={focus ? 'focus' : ''}>{props.label}</Label>
      <Input 
      onBlur={inputBlur}
      onFocus={inputFocus} 
      onChange={inputChange}
      style={inputStyle}
      value={props.value} />
    </InputContainer>
  )
}