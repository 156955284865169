import styled from 'styled-components'
import { PhoneOutlined, MailOutlined } from '@ant-design/icons';
import {
  Space
} from 'antd'

const LineContainer = styled.div`
  width: 100%;
  height: 30px;
  background-color: var(--momp-light-bg-color);
  padding-right: 20px;
`

const LinkA = styled.a`
  color: var(--momp-font-color);
  font-size: 14px;
  &:hover {
    color: var(--momp-pattern-color);
  }
`

export default function HeaderLine() {
  return (
    <LineContainer className='flex-center-end'>
      <Space size={40}>
        <LinkA href='tel://'>
          <PhoneOutlined style={{fontSize: '14px'}} className='margin-r-8'/>
          183-0000-0000
        </LinkA>
        <LinkA href="mailto://">
          <MailOutlined style={{fontSize: '14px'}} className='margin-r-8'/>
          123455@qq.com
        </LinkA>
      </Space>
    </LineContainer>
  )
}