import React, { lazy, Suspense } from 'react'
import { Router, Route } from 'react-router'
import { createHashHistory } from 'history'
import { Layout } from 'antd';

import Loading from './components/Loading'
import HeaderLayout from './components/Header';
import FooterLayout from '@/components/Footer'
import Tag from './components/Tag';

import './App.css';
const { Header, Footer, Content } = Layout;


/**
 * Suspense Waiting
 */
 const WaitingComponent = (Component) => {
  return () => (
    <Suspense fallback={<Loading />}>
      <Component />
    </Suspense>
  )
}

const headerStyle = {
  height: 'auto',
  padding: 0,
  lineHeight: 'inherit',
  color: 'var(--momp-font-color)',
  background: 'none'
}

const footerStyle = {
  padding: 0,
  background: 'transparent'
}

const Home = lazy(() => import('./pages/home-page'))
const Classify = lazy(() => import('./pages/classify-page'))
const NewGoods = lazy(() => import('./pages/new-page'))
const CollectPage = lazy(() => import('./pages/collect-page'))
const OrderPage = lazy(() => import('./pages/order-page'))
const ShoppingPage = lazy(() => import('./pages/shopping-page'))
const DetailsPage = lazy(() => import('./pages/details-page'))

function App() {
  return (
    <div>
        <Router history={createHashHistory()}>
          <Header style={headerStyle}>
            <HeaderLayout/>
          </Header>
          <Content>
            <Tag />
            <Route exact path="/" component={WaitingComponent(Home)}/>
            <Route path="/home" component={WaitingComponent(Home)}/>
            <Route path='/classify' component={WaitingComponent(Classify)} />
            <Route path='/newgoods' component={WaitingComponent(NewGoods)} />
            <Route path='/collect' component={WaitingComponent(CollectPage)} />
            <Route path='/order' component={WaitingComponent(OrderPage)} />
            <Route path='/shoppingcar' component={WaitingComponent(ShoppingPage)} />
            <Route path='/details' component={WaitingComponent(DetailsPage)} />
          </Content>
          <Footer style={footerStyle}>
            <FooterLayout/>
          </Footer>
        </Router>
    </div>
  )
}

export default App