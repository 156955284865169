import styled from 'styled-components'
import Icon from '@/utils/icon'
import './index.less'

const ImageContainer = styled.div`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  background: url(${props => props.src || ''});
  background-size: ${props => props.type || 'cover'};
  background-position: center;
  transition: all .6s ease-in;
  transform: ${props => props.hover ? 'scale(1.09)' : 'scale(1)'};
`

function Image(props) {
  const size = {
    width: props.width || '100%',
    height: props.height || '100%',
    overflow: 'hidden'
  }

  return (
    <div style={size}>
      <ImageContainer 
      width={props.width} 
      height={props.height} 
      src={props.src} 
      type={props.type} 
      hover={props.hover} 
      className={[props.className || '', 'momp-image']} />
    </div>
  )
}

function ButtonToast(props) {
  const styles = {
    width: '150px',
    height: '70px',
    textAlign: 'center',
    lineHeight: '70px',
    color: '#fff',
    fontSize: '16px',
    cursor: 'default',
    // pointerEvents: 'none',
    ...props.styles
  }

  const ltp = {
    position: 'absolute',
    left: 0,
    top: 0,
  }

  const lbp = {
    position: 'absolute',
    left: 0,
    bottom: 0,
  }

  const rtp = {
    position: 'absolute',
    right: 0,
    top: 0,
  }

  const rbp = {
    position: 'absolute',
    right: 0,
    bottom: 0
  }

  const lt = {
    transform: 'rotate(45deg)',
    ...ltp
  }

  const lb = {
    transform: 'rotate(-45deg)',
    ...lbp
  }

  const rt = {
    transform: 'rotate(135deg)',
    ...rtp
  }

  const rb = {
    transform: 'rotate(-135deg)',
    ...rbp
  }

  return (
    <div style={styles} className='momp-button'>
      {props.content}
      <span className='momp-button-model'></span>
      <span className='momp-button-model'></span>
      <span className='momp-button-model'></span>
      <span className='momp-button-model'></span>
      <Icon style={lt} type="icon-xiangzuo1" />
      <Icon style={lb} type="icon-xiangzuo1" />
      <Icon style={rt} type="icon-xiangzuo1" />
      <Icon style={rb} type="icon-xiangzuo1" />
    </div>
  )
}

export {
  Image,
  ButtonToast
}