import styled from 'styled-components'

const CopyContainer = styled.div`
  width: 100%;
  height: 60px;
  padding: 30px calc(5% + 20px);
`

export default function Copy() {
  return (
    <CopyContainer className='flex-center'>
      <span>版权所有 © 2021 Momp保留所有权利</span>
    </CopyContainer>
  )
}