import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import {
  Space,
  Badge,
  Modal,
  Button,
  Drawer,
  Menu
} from 'antd'

import Icon from '@/utils/icon'
import InputText from '@/components/Input'
import { useWindowSize } from '@/hook/index'

import Login from '@/layout/login'
import Register from '@/layout/register'
import Forgot from '@/layout/forgot'

const TabsContainer = styled.div`
  width: 100%;
  height: 70px;
  padding: 0 20px;
  background-color: var(--momp-bg-lucency-light);
`

const Logo = styled.h1`
  width: auto;
  text-transform:uppercase;
  font-weight: bolder;
  margin: 0;
`

const Tabs = styled.div`
  font-size: 14px;
  font-weight: bold;
`

const Tab = styled.div.attrs({
  className: `transition cursor`
})`
  color: var(--momp-font-color);
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    background-color: var(--momp-pattern-color);
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    transition: all .3s ease-out;
  }
  &:hover {
    color: var(--momp-pattern-color);
    opacity: .8;
  }
  &.active {
    color: var(--momp-pattern-color);
    &:after {
      width: 100%;
    }
  }
`

const SearchMode = styled.div`
  position: relative;
`

const searchButton = {
  width: '120px',
  height: '61px',
  position: 'absolute',
  right: '0',
  top: '0'
}

export default function HeaderTabs(props) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('')
  const [mini, setMini] = useState(false)
  const [visible, setVisible] = useState(false);
  const [userDrawer, setUserDrawer] = useState(false)
  const [layout, setLayout] = useState(2)
  const { pathname } = useLocation()
  let history = useHistory()

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const size = useWindowSize()

  const tabList = [{
    name: '首页',
    icon: 'icon-shouye',
    path: '/home'
  },{
    name: '商品分类',
    icon: 'icon-leimu',
    path: '/classify'
  },{
    name: '最新商品',
    icon: 'icon-zuixin',
    path: '/newgoods'
  },{
    name: '收藏',
    icon: 'icon-huiyuan',
    path: '/collect'
  },{
    name: '订单',
    icon: 'icon-huiyuan',
    path: '/order'
  },{
    name: '购物车',
    icon: 'icon-huiyuan',
    path: '/shoppingcar'
  },{
    name: '详情页',
    icon: 'icon-huiyuan',
    path: '/details'
  }]

  useEffect(() => {
    size.width <= 900 ? setMini(true) : setMini(false)
  }, [size.width])

  useEffect(() => {
    watchPath(pathname)
  }, [pathname])// eslint-disable-line react-hooks/exhaustive-deps

  function watchPath(path) {
    tabList.map((item, index) => {
      const { path: _path } = item
      if(_path === path) {
        setActiveIndex(index)
        return true
      }else {
        return false
      }
    })
  }

  function changeRouter(index, path) {
    setActiveIndex(index)
    history.push(path)
  }

  function toLove() {
    history.push('/collect')
  }

  function userLayout() {
    setUserDrawer(true)
  }

  function toShoppingCar() {
    history.push("/shoppingcar")
  }

  return (
    <TabsContainer className='blur flex-center-between'>
      {
        mini && (<Icon onClick={showDrawer} type="icon-qiapianxingshi" />)
      }
      <Logo>
        momp
      </Logo>
      {
        !mini && (
          <Tabs>
            <Space size={20}>
              {
                tabList.map((item, index) => (
                  <Tab
                    onClick={() => changeRouter(index, item.path)}
                    className={ activeIndex === index ? 'active' : '' }
                    key={index}>
                    {item.name}
                  </Tab>
                ))
              }
            </Space>
          </Tabs>
        )
      }
      <Space size={10}>
        <Icon type='icon-sousuo' onClick={() => setIsModalVisible(true)} />
        <Icon onClick={userLayout} type='icon-yonghu' />
        <Badge dot>
          <Icon onClick={toLove} type='icon-xihuan' />
        </Badge>
        <Badge dot>
          <Icon onClick={toShoppingCar} type='icon-gouwuche' />
        </Badge>
      </Space>
      <Modal
      closable={false}
      footer={null}
      maskClosable
      onCancel={() => setIsModalVisible(false)}
      visible={isModalVisible}>
        <SearchMode>
          <InputText
            label='搜索'
            value={searchValue}
            set={setSearchValue} />
          <Button
            style={searchButton}
            type='primary'><Icon
            type='icon-sousuo' /></Button>
        </SearchMode>
      </Modal>
      <Drawer
        title="MOMP"
        placement="left"
        onClose={onClose}
        visible={visible}>
        <Menu
          style={{ width: 256, fontSize: 16 }}
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          mode="inline"
        >
          {
            tabList.map((item, index) => (
              <Menu.Item
                onClick={() => changeRouter(index, item.path)}
                className={ activeIndex === index ? 'active' : '' }
                key={index}>
                <Icon style={{fontSize: 18, marginRight: 15}} type={item.icon} />
                {item.name}
              </Menu.Item>
            ))
          }
        </Menu>
      </Drawer>
      <Drawer
        title="登录"
        placement="right"
        width={340}
        onClose={() => setUserDrawer(false)}
        visible={userDrawer}>
        <div style={{padding: '20px'}}>
          {
            layout === 0 ? (
              <Login set={setLayout} />
            ) : layout === 1 ? (
              <Register set={setLayout} />
            ) : (
              <Forgot set={setLayout} />
            )
          }
        </div>
      </Drawer>
    </TabsContainer>
  )
}