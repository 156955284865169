import { useState, useEffect } from 'react'

/**
 * 自定义Hook
 * @returns { Object } useWindowSize
 */
function useWindowSize() {
    const getWindowSize = () => ({
        height: window.innerHeight,
        width: window.innerWidth,
    })

    const [windowSize, setWindowSize] = useState(getWindowSize())

    function handleResize() {
        setWindowSize(getWindowSize())
    }

    useEffect(() => {
    	// 监听
        window.addEventListener('resize', handleResize)
        
        // 销毁
        return () => window.removeEventListener('resize', handleResize)
    }, [])// eslint-disable-line react-hooks/exhaustive-deps
    return windowSize
}

function useScroll() {
    const [top, setTop] = useState(0)

    function handleScroll() {
        setTop(document.documentElement.scrollTop||document.body.scrollTop)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('resize', handleScroll)
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    return top
}

export {
  useWindowSize,
  useScroll
}