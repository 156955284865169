import { useState } from 'react'
import styled from 'styled-components'
import { CloseOutlined } from '@ant-design/icons';

const HeaderToastContainer = styled.div`
  width: 100%;
  height: 40px;
  color: var(--momp-font-color);
  transition: all .3s ease-in-out;
  background-color: var(--momp-bg-lucency-dark)
`

const spanStyle = {
  color: 'var(--momp-bold-pattern-color)',
  fontSize: '16px',
  fontWeight: 'bold'
}

const closeStyle = {
  position: 'absolute',
  right: '20px',
  top: '13px',
  fontSize: '14px'
}
export default function HeaderToast(props) {
  const [none, setNone] = useState(false)
  
  // 隐藏toast提示
  function hideToast() {
    setNone(true)
    setTimeout(() => {
      props.set(false)
    }, 300)
  }
  
  return (
    <HeaderToastContainer className={['flex-center', none ? 'none' : '']}>
      2050-01-01前交易每满&nbsp;&nbsp;<span style={spanStyle}>300</span>&nbsp;&nbsp;优惠&nbsp;&nbsp;<span style={spanStyle}>50</span>。
      <CloseOutlined onClick={hideToast} style={closeStyle} />
    </HeaderToastContainer>
  )
}