import { useState } from 'react';
import styled from 'styled-components'
import InputText from '@/components/Input'
import { Space, Button } from 'antd'

import {
  verifyStyle,
  LUser
} from '../styled'

const FogotContainer = styled.div`
  width: 100%;
`

export default function Fogot(props) {
  const [userName, setUserName] = useState('')
  const [verify, setVerify] = useState('')
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [step, setStep] = useState(1)

  function next() {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setStep(2)
    }, 3000)
  }

  function submit() {
    setSubmitLoading(true)
    setTimeout(() => {
      setSubmitLoading(false)
      props.set(0)
    }, 3000)
  }

  return (
    <FogotContainer>
      {
        step === 1 ? (
          <Space
            size={25}
            style={{width: '100%'}}
            direction="vertical">
            <InputText label='用户名/手机号' value={userName} set={setUserName}/>
            <div style={{
                position: 'relative'
              }}>
              <InputText 
                label='验证码' 
                value={verify} 
                set={setVerify}/>
              <Button
                type='primary'
                style={verifyStyle}>获取验证码</Button>
            </div>
            <Button 
              style={{
                height: '45px',
                fontSize: '16px'
              }} 
              type='primary'
              loading={loading}
              onClick={next}
              block>
              {
                !loading && (
                  '下一步'
                )
              }
            </Button>
          </Space>
        ) : (
          <Space
            size={25}
            style={{width: '100%'}}
            direction="vertical">
            <InputText
              label='密码' 
              value={userName} 
              set={setUserName}/>
            <InputText
              label='确认密码' 
              value={userName} 
              set={setUserName}/>
            <Button 
              style={{
                height: '45px',
                fontSize: '16px'
              }} 
              type='primary'
              loading={submitLoading}
              onClick={submit}
              block>
              {
                !loading && (
                  '确定重置'
                )
              }
            </Button>
          </Space>
        )
      }
      <div
        style={{
          marginTop: 20
        }}>
        <LUser onClick={() => props.set(0)}>返回登录</LUser>
      </div>
    </FogotContainer>
  )
}