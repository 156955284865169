import { useState } from 'react';
import styled from 'styled-components'
import InputText from '@/components/Input'
import { Space, Button } from 'antd'

import {
  verifyStyle,
  LUser
} from '../styled'

const RegisterContainer = styled.div`
  width: 100%;
`

export default function Register(props) {
  const [userName, setUserName] = useState('')
  const [verify, setVerify] = useState('')
  const [pass, setPass] = useState('')
  const [confirmPass, setConfirmPass] = useState('')
  const [loading, setLoading] = useState(false)

  function register() {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      props.set(0)
    }, 3000)
  }

  return (
    <RegisterContainer>
      <Space
        size={25}
        style={{width: '100%'}}
        direction="vertical">
        <InputText label='邮箱/手机号' value={userName} set={setUserName}/>
        <div style={{
          position: 'relative'
        }}>
          <InputText 
            label='验证码' 
            value={verify} 
            set={setVerify}/>
          <Button
            type='primary'
            style={verifyStyle}>获取验证码</Button>
        </div>
        <InputText 
          label='密码' 
          value={pass} 
          set={setPass}/>
        <InputText 
          label='确认密码' 
          value={confirmPass} 
          set={setConfirmPass}/>

        <div>
          <LUser onClick={() => props.set(0)}>返回登录</LUser>
        </div>

        <Button 
          style={{
            height: '45px',
            fontSize: '16px'
          }} 
          type='primary'
          loading={loading}
          onClick={register}
          block>
          {
            !loading && (
              '立即注册'
            )
          }
        </Button>
      </Space>
    </RegisterContainer>
  )
}