import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import '@/common/global.less'
import { BackTop } from 'antd'
import styled from 'styled-components'
import Icon from '@/utils/icon'

const BackTopButton = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 3px;
  background: #fff;
  color: #878787;
  box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);
`


moment.locale('zh-cn');

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <App />
    <BackTop
      duration='500'
      visibilityHeight='800' >
      <BackTopButton className='flex-center'>
        <Icon type='icon-dingbu' />
      </BackTopButton>
    </BackTop>
  </ConfigProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
